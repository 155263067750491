import React, { Fragment } from "react";
import styled, { ThemeProvider, keyframes } from "styled-components";
import { Flex, Headline, Animate, State } from "contist-ui";
import { Link } from "@reach/router";
import Lottie from "react-lottie";
import { Text, trans } from "react-easy-i18n";

import animationData from "../animations/pulse.json";
import Card from "../components/Card";

const Logo = styled.img`
  height: 75%;
`;

const LogoBadge = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  bax-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10;

  @media only screen and (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const StoreLogo = styled.img`
  height: 50px;
  margin: 5px 15px;
  position: relative;
  z-index: 10;
`;

const FullBgWrap = styled.div`
  height: 95%;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullBg = styled.img`
  height: 120vh;
  width: auto;
`;

const PhoneWrap = styled.div`
  position: relative;
  margin: 0 auto;
  height: auto;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-top: 50px;
  }
`;

const PulseWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  height: auto;
  max-height: 700px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Phone = styled.img`
  height: 80vh;
  width: auto;
  max-width: 400px;
  max-height: 700px;
  margin-top: -25vh;
`;

const Circle = styled.div`
  position: absolute;
  left: -5vw;
  right: 0;
  top: -50px;
  margin: 0 auto;
  height: 100px;
  width: 110vw;
  border-radius: 50%;
  background: #fff;
`;

export default () => (
  <Fragment>
    <Flex
      position="relative"
      justifyContent="center"
      width="100vw"
      height="100vh"
      alignItems="center"
      padding="10vw"
      zIndex="100"
      flexDirection="column"
    >
      <Flex
        position="absolute"
        left="0px"
        top="0px"
        width="100vw"
        height="100vh"
        zIndex="0"
        background="url(./images/hero.jpg)no-repeat center center"
        backgroundSize="cover"
        opacity="0.75"
      ></Flex>
      <FullBgWrap>
        <FullBg src="./images/hero.png" alt="" />
      </FullBgWrap>
      <LogoBadge>
        <Logo src="./images/logo.svg" alt="" />
      </LogoBadge>
      <Headline
        as="h1"
        color="#FFF"
        marginTop="30px"
        maxWidth="650px"
        textAlign="center"
        padding="0 25px"
        animated
      >
        {trans("home.hero")}
      </Headline>

      <Flex
        marginTop="50px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        style={{ flexWrap: "wrap" }}
      >
        <a href="https://apps.apple.com/de/app/lyno/id1457198506?l=de&ls=1">
          <StoreLogo src="./images/appstore.svg" alt="Apple Appstore iOS" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.lyno.live">
          <StoreLogo src="./images/playstore.svg" alt="Apple Appstore iOS" />
        </a>
      </Flex>
    </Flex>
    <Flex
      width="100vw"
      height="auto"
      alignItems="center"
      zIndex="100"
      position="relative"
      background="#FFF"
      flexDirection="column"
      padding="10vh 0"
    >
      <Circle />
      <PhoneWrap>
        <PulseWrap style={{ height: "80vh", top: "-25vh" }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData,
            }}
            height={700}
            width={700}
          />
        </PulseWrap>
        <Card shadow={0}>
          <Phone src="./images/phone.png" alt="" />
        </Card>
      </PhoneWrap>
      <Headline
        as="h2"
        color="#FF6B87"
        marginTop="30px"
        maxWidth="650px"
        textAlign="center"
        padding="0 25px"
        animated
        onVisible
      >
        {trans("home.desc")}
      </Headline>
    </Flex>
  </Fragment>
);
