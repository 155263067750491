import React, { useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const Card = styled(animated.div)`
  width: auto;
  height: auto;
  background-position: center center;
  transition: box-shadow 0.5s;
  will-change: transform;
  user-select: none;
  ${p =>
    p.shadow > 0 ? "box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);" : ""}

  &:hover {
    ${p =>
      p.shadow > 0
        ? "box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);"
        : ""}
  }
`;

function Tilt({ children, shadow, max }) {
  const card = useRef(null);

  const calc = (x, y) => {
    let rect = card.current.getBoundingClientRect();
    let width = card.current.offsetWidth;
    let height = card.current.offsetHeight;
    let left = rect.left;
    let top = rect.top;

    let _x = (x - left) / width;
    let _y = (y - top) / height;
    const tiltX = -1 * (max / 2 - _x * max);
    const tiltY = -1 * (_y * max - max / 2);
    return [tiltY, tiltX, 1.05];
  };

  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 }
  }));

  return (
    <Card
      ref={card}
      shadow={shadow}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
    >
      {children}
    </Card>
  );
}

Tilt.defaultProps = {
  max: 5
};

export default Tilt;
